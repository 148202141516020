export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-west-2",
    BUCKET: "jnog-notes-app-uploads"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://7zzcz33s06.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_mOg1Xjfxf",
    APP_CLIENT_ID: "3hl1ujg6u1lonslgfhmm1l4v8h",
    IDENTITY_POOL_ID: "us-west-2:80a850d2-3d90-4258-a779-94608ce7f938"
  }
};