import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true});
    const lowercaseEmail = this.state.email.toLowerCase();
  
    try {
      await Auth.signIn(lowercaseEmail, this.state.password);
      this.props.userHasAuthenticated(true);
    } catch (e) {
      if(e.name === "UserNotConfirmedException")
      {
        this.reconfirmUser();
      }
      else
      {
        alert(e.message);
      }

      this.setState({ isLoading: false });
    }
  }

  reconfirmUser = async event => {
    const lowercaseEmail = this.state.email.toLowerCase();

    try {
      const newUser = await Auth.resendSignUp(lowercaseEmail);

        const signupConfirmLocation = {
          pathname: "/signup",
          state: {
            isLoading: false,
            email: lowercaseEmail,
            password: this.state.password,
            confirmPassword: this.state.password,
            confirmationCode: "",
            newUser: newUser
          }
        }
        // TODO: Don't do this if the authentication fails
        this.props.history.push(signupConfirmLocation);
    } catch (e) {
      alert(e.message);
    }
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in..."
          />
        </form>
      </div>
    );
  }
}